@import "~/src/scss/index.scss";

.email-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:nth-last-of-type(2){
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
}