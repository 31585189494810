@import "~/src/scss/index.scss";

.how-my-courses-work{
    margin-top: 80px;

    > h2 {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        color: $text-light;
        margin: 0;
    }

    > h4 {
        color: $text-light;
        font-weight: 100;
        margin: 0;
        text-align: center;
        font-size: 14px;
        opacity: 0.6;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;

        > div {
            width: 33.33%;
            color: $text-light;
            text-align: center;

            > div {
                margin: 15px;
                padding: 30px;
                box-shadow: 0 0 10px 2px rgb(0 0 0 / 75%);
                border-radius: 10px;

                i {
                    margin: 0;
                    font-size: 40px;
                }

                h3 {
                    margin: 10px 0 6px 0;
                }

                p {
                    opacity: 0.4;
                    font-size: 12px;
                }
            }
        }

    }
}


// BREAKPOINTS

@media only screen and (max-width: 600px) {
    .how-my-courses-work {

        > h4 {
            margin-bottom: 15%;
        }

        &__items {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0 5% 0 5%;

             > div {
                width:100%;
             }
        }
    }
}
