@import "~/src/scss/index.scss";

.home-courses {
    > h2 {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        color: $text-light;
        margin: 50px 0 30px 0;
    }

    &__all-courses {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        > a {
            width: 50%;
            display: flex;
            padding: 10px;
            &:hover {
                opacity: 0.6;
            }

            .ui.image {
                height: 100px;
            }

            > div {
                display: flex;
                flex-direction: column;
                padding: 0 10px;

                > span {
                    color: $text-light;
                    &:first-of-type {
                        font-weight: bold;
                        font-size: 16px;
                    }
                    &:last-of-type{
                        opacity: 0.4;
                        font-size: 12px;
                        padding-top: 6px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                    }
                }
            }
        }
    }
    &__more {
        display: flex;
        justify-content: center;
        padding: 20px 0;

        > .ui.button {
            width: 200px;
        }
    }
}

// BREAKPOINTS

@media only screen and (max-width: 600px) {

    .home-courses{

        &__all-courses {
            display: grid;
            grid-template-columns: 1fr;
            
            > a {
                width: 100%;
                display: flex;
                flex-direction: column;

                .ui.image {
                    padding: 0 120px 0 120px;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                > div {
                    padding: 0;
                    text-align: center;

                    > span {
                        &:first-of-type {
                            font-weight: bold;
                            font-size: 16px;
                            margin-top: 8px;
                        }
                        &:last-of-type {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
    
        }
    
        &__more {
            display: flex;
            justify-content: center;
            padding: 20px 0;
    
            > .ui.button {
                width: 300px;
            }
        }
    }
    }